import './styles.global.less';
import './styles.global.scss';

// React parts
import React from 'react';
import ReactDOM from 'react-dom/client';

// React Router, for controlling browser routes.  We'll feed in our custom
// `history` instance that's imported below, so that we have a single store of
// truth for routing
import { BrowserRouter } from '@fjedi/react-router-helpers';

// Apollo Provider. This HOC will 'wrap' our React component chain
// and handle injecting data down to any listening component
import { ApolloProvider, browserClient, mergePaginatedList, mergeInfiniteList } from '@fjedi/graphql-react-components';

// Multi-lang support
import languageDetector from 'i18next-browser-languagedetector';

// Cookies
import { CookiesProvider } from 'react-cookie';

//
import * as Sentry from '@sentry/browser';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import initSentry from 'kit/lib/sentry';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import initTranslations, { I18nextProvider } from 'kit/lib/intl';

import NotificationsContext from 'src/components/ui-kit/notifications';
// Main component -- i.e. the 'root' React component in our app
import Main from './components/root';
//
import { API_URL, SUBSCRIPTIONS_URL, SENTRY_PUBLIC_DSN } from './constants';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.SERVER = false;

//
const i18n = initTranslations({
  languageDetector,
  detectionProps: {
    order: ['querystring', 'cookie', 'session', 'navigator'],
    // lookupLocalStorage: 'lang',
    lookupQuerystring: 'lang',
    lookupSession: 'lang',
    lookupCookie: 'lang',
    // lookupPath: 'lang',
    // lookupFromPathIndex: 0,
  },
});

//
initSentry(Sentry, {
  debug: true,
  dsn: SENTRY_PUBLIC_DSN,
});

const cacheOptions = {
  typePolicies: {
    UsedItem: { keyFields: ['id', 'title'] },
    Query: {
      fields: {
        getFlights: mergePaginatedList,
        getCompanies: mergePaginatedList,
        getDevices: mergePaginatedList,
        getDeviceLogs: mergePaginatedList,
        getEvents: mergePaginatedList,
        getEventLocations: mergePaginatedList,
        getMediaFolders: mergeInfiniteList,
        getMediaItems: mergePaginatedList,
        getNotifications: mergePaginatedList,
        getPlaylists: mergePaginatedList,
        getTemplates: mergePaginatedList,
        getTemplateAreas: mergePaginatedList,
        getProjects: mergePaginatedList,
        getSchedules: mergeInfiniteList,
        getUserRoles: mergePaginatedList,
        getUsers: mergePaginatedList,
      },
    },
  },
};

// Create a new browser Apollo client
const client = browserClient({
  url: API_URL as string,
  wsURL: SUBSCRIPTIONS_URL as string,
  cacheOptions,
});

const appContainer = document.getElementById('root');

// Create a root.
const root = ReactDOM.createRoot(appContainer!);

// During an update, there is no need to pass the container again
root.render(
  <ApolloProvider client={client}>
    <CookiesProvider>
      <I18nextProvider i18n={i18n}>
        <NotificationsContext>
          <BrowserRouter>
            <Main />
          </BrowserRouter>
        </NotificationsContext>
      </I18nextProvider>
    </CookiesProvider>
  </ApolloProvider>,
);
