/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { FC, memo, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { RiDeleteBinLine } from 'react-icons/ri';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import { ViewerContext } from '@fjedi/react-router-helpers';
import { useGetTemplateQuery, Viewer } from 'src/graphql/generated';
import Tooltip from 'src/components/ui-kit/tooltip';
import { Form, FormItem } from 'src/components/ui-kit/form';
import { Input } from 'src/components/ui-kit/input';
import ModalPopup from 'src/components/ui-kit/modal-popup';
import Button from 'src/components/ui-kit/buttons';
import CustomDatePicker from 'src/components/ui-kit/datepicker';
import { Image } from 'src/components/ui-kit/thumbnail';
import { colorTheme } from 'src/components/ui-kit/theme';
import time, { TimeInstance } from 'src/helpers/time';
import logger from 'src/helpers/logger';
import { EventsContext } from './events-context';
import AddLogoModal from './add-logo-modal';
import type { EventFormFields, EventFormLogo, ModalProps, EventLocation, EventFormTemplate } from './events';
import { EventModalContext } from './event-modal-context';
import { getMaxValueForOffset, validateEventDuration } from './helpers';
import AddTemplateModal from './add-template-modal';

const Modal = styled(ModalPopup)`
  .modal-layout {
    padding: 1.25rem 0;

    .modal-content {
      max-height: 100%;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;
const DatePicker = CustomDatePicker as FC<PickerProps<TimeInstance>>;

const AttachmentFooter = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 2.5rem;
  align-items: center;
  padding: 0 0.9375rem;

  background: ${colorTheme.dark};

  h4 {
    color: hsl(255, 100%, 100%);
    font-size: 1rem;
    font-weight: 700;
    flex-grow: 1;
    margin-left: auto;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    cursor: default;
  }

  .ant-btn {
    color: ${colorTheme.secondary};
  }
`;

const ImageContainer = styled.div`
  display: none;
  flex-direction: column;

  position: relative;
  overflow: hidden;
  margin-top: 1.25rem;
  border: unset;
  border-radius: 0.625rem;

  & > .ant-image {
    max-height: 178px;

    > .ant-image-img {
      position: relative;
      top: -1.25rem;
    }
  }
`;

const AttachmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  width: 100%;
  margin-bottom: 1.25rem;

  &.with-logo,
  &.with-template {
    ${ImageContainer} {
      border: 1px solid hsla(0, 0%, 0%, 0.2);
    }

    ${AttachmentFooter}, ${ImageContainer} {
      display: flex;
    }
  }

  &.no-logo,
  &.no-template {
    ${ImageContainer} {
      border: unset;
    }

    ${ImageContainer}, ${AttachmentFooter} {
      display: none;
    }
  }
`;

const FormContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto [title] auto [description] auto [date] auto [time] auto [time-conditions-desc] auto [before-after] auto [logo] auto [footer];
  grid-template-areas:
    'title title'
    'description description'
    'time-start time-end'
    'date-start date-end'
    'time-conditions-desc time-conditions-desc'
    'before after'
    'logo template'
    'footer footer';
  row-gap: 0.625rem;
  column-gap: 1.25rem;

  .title {
    grid-column: 1 / span 2;
    grid-row: title;
    grid-area: title;
  }

  .description {
    grid-column: 1 / span 2;
    grid-row: description;
    grid-area: description;
  }

  .startTime {
    grid-column: 1;
    grid-row: time;
    grid-area: time-start;
  }

  .endTime {
    grid-column: 2;
    grid-row: time;
    grid-area: time-end;
  }

  .startDate {
    grid-column: 1;
    grid-row: date;
    grid-area: date-start;
  }

  .endDate {
    grid-column: 2;
    grid-row: date;
    grid-area: date-end;
  }

  ${AttachmentContainer} {
    &.with-logo,
    &.no-logo {
      grid-column: 1;
      grid-row: logo;
      grid-area: logo;
    }

    &.with-template,
    &.no-template {
      grid-column: 2;
      grid-row: logo;
      grid-area: template;
    }
  }

  .timeConditionsDesc {
    grid-column: 1 / span 2;
    grid-row: time-conditions-desc;
    grid-area: time-conditions-desc;

    margin: 0;
  }

  .offsetForShowingBefore {
    grid-column: 1;
    grid-row: before-after;
    grid-area: before;
  }

  .offsetForShowingAfter {
    grid-column: 2;
    grid-row: before-after;
    grid-area: after;
  }
`;

const FormFooter = styled.footer`
  display: flex;
  justify-content: center;
  padding-top: 0.875rem;

  grid-column: 1 / span 2;
  grid-row: footer;
  grid-area: footer;

  .ant-btn {
    width: 8.75rem;
    margin: 0 0.625rem 0;
  }
`;

export const AddEventModal: FC<ModalProps> = ({ onClose }) => {
  const viewer = useContext(ViewerContext) as Viewer;
  const { createEvent, updateEvent, warn, isLoading } = useContext(EventsContext);
  const { isVisible, event, location } = useContext(EventModalContext);
  const { id: locationId, occupiedTime } = location as EventLocation;
  const [form] = Form.useForm<EventFormFields>();
  const { setFieldsValue, resetFields, getFieldsValue } = form;

  const defaultLogo = useMemo(
    () =>
      ({
        id: undefined,
        url: undefined,
        title: undefined,
      }) as EventFormLogo,
    [],
  );
  const { data: templateRes, loading: defaultTemplateLoading } = useGetTemplateQuery({
    variables: { id: event?.templateId ?? viewer.primaryProject!.defaultEventTemplateId },
    fetchPolicy: 'network-only',
  });
  const [eventTemplate, setEventTemplate] = useState<EventFormTemplate>({ id: undefined, title: '', url: undefined });
  useEffect(() => {
    const { id, title, preview } = templateRes?.getTemplate ?? {};
    const { url } = preview ?? {};
    const template: EventFormTemplate = { id, title, url };
    setEventTemplate(template);
    setFieldsValue({ template });
  }, [templateRes, isVisible, setFieldsValue]);

  const [eventLogo, setEventLogo] = useState<EventFormLogo>(defaultLogo);

  const { t } = useTranslation();

  const initialValues = useMemo(() => {
    const defaultDate = time().set('m', 0).set('s', 0);
    const defaultStart = defaultDate.set('h', 9);
    const defaultEnd = defaultDate.set('h', 14);
    const tz = (window as typeof window & { timezone?: string }).timezone;
    const startDate = event ? time(event?.start, tz) : defaultStart;
    const endDate = event ? time(event?.end, tz) : defaultEnd;
    return {
      title: event?.title ?? '',
      description: event?.description ?? '',
      logo: event ? { id: event?.logoId, url: event.logoUrl, title: event.logoTitle } : defaultLogo,
      template: event?.templateUrl
        ? { id: event?.templateId, url: event.templateUrl, title: event.templateTitle }
        : eventTemplate,
      startTime: startDate,
      startDate,
      endTime: endDate,
      endDate,
      offsetForShowingBefore: event?.offsetForShowingBefore ?? 0,
      offsetForShowingAfter: event?.offsetForShowingAfter ?? 0,
    };
  }, [event, defaultLogo, eventTemplate]);

  useEffect(() => {
    if (event) {
      let logo: EventFormLogo = defaultLogo;

      if ('logoId' in event) {
        logo = { id: event.logoId, url: event.logoUrl, title: event.logoTitle };
        setEventLogo(logo);
      }

      setFieldsValue({ logo });
    }
  }, [event, setFieldsValue, defaultLogo]);

  const onCancel = useCallback(() => {
    const { id, title, preview } = templateRes?.getTemplate ?? {};
    const { url } = preview ?? {};
    setEventTemplate({
      id,
      title,
      url,
    });
    setEventLogo(defaultLogo);
    resetFields();
    onClose();
  }, [templateRes, defaultLogo, resetFields, onClose]);

  const handleLogoSelect = useCallback(
    (item: EventFormLogo) => {
      const { url, id, title } = item;

      setEventLogo(item);
      setFieldsValue({ logo: { url, id, title } });
    },
    [setFieldsValue],
  );

  const handleTemplateSelect = useCallback(
    (item: EventFormTemplate) => {
      setEventTemplate(item);
      setFieldsValue({ template: item });
    },
    [setFieldsValue],
  );

  const onSubmit = useCallback(
    (values: unknown) => {
      const {
        logo: eventLogoData,
        template: eventTemplateData,
        startTime,
        startDate,
        endTime,
        endDate,
        description,
        title,
        offsetForShowingBefore,
        offsetForShowingAfter,
      } = values as EventFormFields;
      const { id: logoId } = eventLogoData ?? eventLogo ?? defaultLogo;
      const { id: templateId } = eventTemplateData ?? eventTemplate;
      const projectBasedOffset = time().tz().utcOffset();
      const start = startDate
        .hour(startTime.hour())
        .minute(startTime.minute())
        .add(-projectBasedOffset, 'minute')
        .utc(true);
      const end = endDate.hour(endTime.hour()).minute(endTime.minute()).add(-projectBasedOffset, 'minute').utc(true);

      const query = event ? updateEvent : createEvent;
      const input = {
        title,
        description,
        logoId: logoId ?? null,
        templateId: templateId ?? null,
        start,
        end,
        locationId,
        offsetForShowingBefore:
          typeof offsetForShowingBefore === 'string' ? parseInt(offsetForShowingBefore, 10) : offsetForShowingBefore,
        offsetForShowingAfter:
          typeof offsetForShowingAfter === 'string' ? parseInt(offsetForShowingAfter, 10) : offsetForShowingAfter,
      };

      if (validateEventDuration(input, occupiedTime)) {
        const variables = event ? { id: event?.id, input } : { input };

        query({
          variables,
        })
          .then((res: any) => {
            if (res.errors) logger('Encountered error while creating event', { errors: res?.errors });
            else {
              logger('Successfully created/updated event', { res });
              onCancel();
            }
          })
          .catch(logger);
      } else warn('There is already an event scheduled for selected time!');
    },
    [eventLogo, eventTemplate, defaultLogo, event, updateEvent, createEvent, locationId, occupiedTime, warn, onCancel],
  );

  const removeLogo = useCallback(() => {
    setEventLogo(defaultLogo);
    setFieldsValue({ logo: defaultLogo });
  }, [defaultLogo, setFieldsValue]);

  const hasLogo = useMemo(() => !!eventLogo.id, [eventLogo.id]);
  const hasTemplate = useMemo(() => !!eventTemplate?.id, [eventTemplate.id]);

  const logoDisplayData = useMemo(() => {
    const { title, url } = eventLogo;

    return {
      name: title ?? '',
      src: url ?? '',
    };
  }, [eventLogo]);

  const templateDisplayData = useMemo(() => {
    const { title, url } = eventTemplate;

    return {
      name: title ?? '',
      src: url ?? '',
    };
  }, [eventTemplate]);

  const action = useMemo(() => (!event ? t('Add') : t('Save')), [event, t]);

  useLayoutEffect(() => {
    resetFields();
  }, [event, resetFields]);

  const [maxOffsets, setMaxOffsets] = useState(
    getMaxValueForOffset(initialValues.startDate, initialValues.startTime, initialValues.endTime),
  );

  const handlePickerChange = useCallback(
    (field: string): PickerProps<TimeInstance>['onChange'] =>
      value => {
        setFieldsValue({ [field]: value });
        const { startDate, startTime, endTime } = getFieldsValue();

        setMaxOffsets(getMaxValueForOffset(startDate, startTime, endTime));
      },
    [getFieldsValue, setFieldsValue],
  );

  return (
    <Modal isVisible={isVisible} onCancel={onCancel} title={t(`${action} Event`)} width={440} closeOnBgClick>
      <Form
        name={event ? `edit-event-${event.id}-form` : 'create-event-form'}
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onFinish={onSubmit}>
        <FormContent>
          <FormItem
            label={t('Title')}
            name="title"
            className="title"
            rules={[{ required: true, message: t('Please fill this field') }]}>
            <Input id={event ? `${event.id}-title` : 'title'} placeholder={t('Enter name')} />
          </FormItem>

          <FormItem label={t('Info')} name="description" className="description">
            <Input id={event ? `${event.id}-description` : 'description'} placeholder={t('Enter info')} />
          </FormItem>

          <FormItem label={t('Start time')} name="startTime" className="startTime" rules={[{ required: true }]}>
            <DatePicker
              id={event ? `${event.id}-startTime` : 'startTime'}
              picker="time"
              format="HH:mm"
              defaultPickerValue={initialValues.startTime}
              onChange={handlePickerChange('startTime')}
            />
          </FormItem>
          <FormItem label={t('End time')} name="endTime" className="endTime" rules={[{ required: true }]}>
            <DatePicker
              id={event ? `${event.id}-endTime` : 'endTime'}
              picker="time"
              format="HH:mm"
              defaultPickerValue={initialValues.endTime}
              onChange={handlePickerChange('endTime')}
            />
          </FormItem>

          <FormItem label={t('Start date')} name="startDate" className="startDate" rules={[{ required: true }]}>
            <DatePicker
              id={event ? `${event.id}-startDate` : 'startDate'}
              picker="date"
              format="DD.MM.YYYY"
              defaultPickerValue={initialValues.startDate}
              onChange={handlePickerChange('startDate')}
            />
          </FormItem>
          <FormItem label={t('End date')} name="endDate" className="endDate" rules={[{ required: true }]}>
            <DatePicker
              id={event ? `${event.id}-endDate` : 'endDate'}
              picker="date"
              format="DD.MM.YYYY"
              defaultPickerValue={initialValues.endDate}
              onChange={handlePickerChange('endDate')}
            />
          </FormItem>

          <p className="timeConditionsDesc">{t('Display related content before/after event starts/ends')}</p>
          <FormItem
            label={t('Before event (hours)')}
            name="offsetForShowingBefore"
            className="offsetForShowingBefore"
            rules={[{ required: true }]}>
            <Input
              type="number"
              id={event ? `${event.id}-offsetForShowingBefore` : 'offsetForShowingBefore'}
              min={0}
              max={maxOffsets.before}
            />
          </FormItem>
          <FormItem
            label={t('After event (hours)')}
            name="offsetForShowingAfter"
            className="offsetForShowingAfter"
            rules={[{ required: true }]}>
            <Input
              type="number"
              id={event ? `${event.id}-offsetForShowingAfter` : 'offsetForShowingAfter'}
              min={0}
              max={maxOffsets.after}
            />
          </FormItem>

          <AttachmentContainer className={hasLogo ? 'with-logo' : 'no-logo'}>
            <AddLogoModal onSelectItem={handleLogoSelect} hasLogo={hasLogo} />
            {hasLogo && (
              <ImageContainer>
                <Image src={logoDisplayData.src} preview={false} />
                <AttachmentFooter>
                  <h4 title={logoDisplayData.name}>{logoDisplayData.name}</h4>
                  <Tooltip title={t('Remove')} align={{ offset: [0, 2.5] }}>
                    <Button type="link" onClick={removeLogo} icon={<RiDeleteBinLine />} />
                  </Tooltip>
                </AttachmentFooter>
              </ImageContainer>
            )}
            <FormItem name="logo" noStyle hidden>
              <Input />
            </FormItem>
          </AttachmentContainer>

          <AttachmentContainer className={hasTemplate ? 'with-template' : 'no-template'}>
            <AddTemplateModal onSelect={handleTemplateSelect} hasTemplate={hasTemplate} />
            <FormItem name="template" noStyle hidden>
              <Input />
            </FormItem>
            {hasTemplate && !defaultTemplateLoading && (
              <ImageContainer>
                <Image src={templateDisplayData.src} preview={false} />
                <AttachmentFooter>
                  <h4 title={templateDisplayData.name}>{templateDisplayData.name}</h4>
                </AttachmentFooter>
              </ImageContainer>
            )}
            <FormItem name="template" noStyle hidden>
              <Input />
            </FormItem>
          </AttachmentContainer>

          <FormFooter>
            <Button type="default" htmlType="button" onClick={onCancel}>
              {t('Cancel')}
            </Button>
            <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
              {action}
            </Button>
          </FormFooter>
        </FormContent>
      </Form>
    </Modal>
  );
};

AddEventModal.displayName = 'AddEventModal';

export default memo(AddEventModal);
