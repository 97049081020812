import React, { memo, useContext, useCallback } from 'react';
import { ViewerContext } from '@fjedi/react-router-helpers';
import styled from 'styled-components';
//
import { Mutation } from '@fjedi/graphql-react-components';
import updateUserProfileMutation from 'src/graphql/mutations/update-user-profile.graphql';
import logger from 'src/helpers/logger';

// Inner components
import Tooltip from 'src/components/ui-kit/tooltip';
import { ProjectSelector } from 'src/components/common/project-selector';
import UserProfile from 'src/components/common/user-profile-widget';
import LinkButton from 'src/components/ui-kit/buttons/link-button';
import { SettingOutlined } from 'src/components/ui-kit/icons';
import { Logo } from './logo';

const Header = styled.header`
  background-color: #ffffff;
  box-shadow: 0 0.25rem 0.875rem rgba(0, 0, 0, 0.08);

  .firstRow {
    display: flex;
    min-height: 3.75rem;
    align-items: center;
    position: relative;
    padding: 0 1rem;
    width: 100%;
    max-width: 1332px;
    margin: 0 auto;

    .leftColumn {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      h1 {
        color: #fff;
        font-size: 1.4rem;
        margin-left: 1rem;
        margin-bottom: 0;
        font-weight: 600;
      }
    }
    .rightColumn {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;

      > *:not(:first-child) {
        margin-left: 20px;
      }
    }
  }
  .brand {
    height: 30px;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    .hoteza {
      width: 12.5rem;
    }
  }

  .ant-select-show-search {
    width: 500px;
    max-width: 100%;
  }
  .ant-btn-text {
    color: rgba(0, 0, 0, 0.4);
  }

  .ant-btn-icon-only {
    display: flex;
    align-items: center;
    justify-content: center;

    .anticon {
      display: flex;
    }

    svg {
      font-size: 1.25rem;
    }
  }

  .secondRow {
    border: 0;
    border-radius: 0;
    padding: 0 16px 0.5rem;
  }
`;

const HeaderComponent = () => {
  const viewer = useContext(ViewerContext);
  const onChange = useCallback(
    mutation => (value, option) => {
      logger({ message: 'Selected project', value, option });
      mutation({ variables: { id: viewer.id, input: { primaryProjectId: value } } });
    },
    [viewer.id],
  );

  const onProjectChanged = useCallback(res => {
    logger({ message: 'Changed primary project', res });
    window.location.href = '/';
  }, []);

  if (!viewer) {
    return null;
  }
  //
  return (
    <Header>
      <div className="firstRow">
        <Logo />
        <div className="leftColumn">
          <Mutation mutation={updateUserProfileMutation} onCompleted={onProjectChanged}>
            {mutation => (
              <ProjectSelector groupByCompany value={viewer?.primaryProjectId} onChange={onChange(mutation)} />
            )}
          </Mutation>
        </div>
        <div className="rightColumn">
          {/* <Notifications /> */}
          <Tooltip placement="bottom" title="Profile settings">
            <LinkButton to="/profile" type="text" icon={<SettingOutlined />} />
          </Tooltip>
          <UserProfile />
        </div>
      </div>
    </Header>
  );
};

HeaderComponent.propTypes = {};
HeaderComponent.defaultProps = {};

export default memo(HeaderComponent);
