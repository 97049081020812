const constants = {};

export const HOST = import.meta.env.VITE_HOST || 'localhost';
export const NODE_ENV = import.meta.env.NODE_ENV || 'production';
export const RUNTIME_ENV = import.meta.env.VITE_RUNTIME_ENV || 'production';

export const PROJECT_NAME = import.meta.env.VITE_PROJECT_NAME;
export const APP_VERSION = import.meta.env.VITE_APP_VERSION;

export const API_URL = import.meta.env.VITE_API_URL;
export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
export const SUBSCRIPTIONS_URL = import.meta.env.VITE_SUBSCRIPTIONS_URL;

export const CLOUDINARY_CLOUDNAME = import.meta.env.VITE_CLOUDINARY_CLOUDNAME;

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const SENTRY_PUBLIC_DSN = import.meta.env.VITE_SENTRY_PUBLIC_DSN;
export const SENTRY_ENV = import.meta.env.VITE_SENTRY_ENV || import.meta.env.MODE;

export const GOOGLE_PLACES_API_KEY = import.meta.env.VITE_GOOGLE_PLACES_API_KEY;

export default constants;
