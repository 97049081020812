import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import semver from 'semver';
import { useTranslation } from 'react-i18next';
import { InfoCircleFilled } from '@ant-design/icons';
import { HiExclamationCircle } from 'react-icons/hi';

import { colorTheme } from 'src/components/ui-kit/theme';
import Select, { Option } from 'src/components/ui-kit/select';
import Button from 'src/components/ui-kit/buttons';
import { FormItem } from 'src/components/ui-kit/form';

import { useGetDeviceFirmwaresQuery } from 'src/graphql/generated';

const FooterModal = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 0.75rem;

  .ant-btn {
    width: 8.75rem;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
`;

type FirmwareVersionModalProps = {
  isLoading?: boolean;
  onFirmwareSelect?(_version?: string): void;
  onClose?(): void;
  currentVersion: string;
  vendor?: string | null;
};

const Container = styled.div`
  text-align: left;
  font-size: 0.875rem;

  & > p {
    margin-bottom: 0.625rem;

    & > strong {
      font-weight: 900;
      font-size: 0.75rem;
      text-shadow: 0.5px -0.5px 1px ${colorTheme.dark};
    }
  }

  & > em {
    display: inline-block;
    margin-bottom: 0.875rem;

    & + strong {
      font-weight: 900;
      font-size: 0.75rem;
      font-style: italic;
      text-shadow: 0.5px -0.5px 1px ${colorTheme.dark};
    }

    &:nth-of-type(2) {
      margin-bottom: 1.25rem;
    }

    &:last-of-type:not(:nth-of-type(2)) {
      margin: 0.75rem 0 0;
    }
  }

  & > .ant-form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;

    & > .ant-form-item-label > label {
      width: 100%;
      text-align: left;
    }
  }
`;

const InfoIcon = styled(InfoCircleFilled)`
  &.anticon-info-circle {
    width: 1.25rem;
    height: 1.25rem;
    margin: 0 0.125rem 0 0;

    & > svg {
      height: 1rem;
      fill: ${({ color }) => color};
    }
  }
`;

const WarningIcon = styled(HiExclamationCircle)`
  width: 1.25rem;
  height: 1.25rem;
  position: relative;
  top: 0.325rem;
  margin: 0 0.125rem 0 0;
`;

export const FirmwareVersionModal: FC<FirmwareVersionModalProps> = ({
  isLoading: deviceUpdating,
  onFirmwareSelect,
  onClose,
  currentVersion,
  vendor,
}) => {
  const [selectedVersion, setSelectedVersion] = useState<string>();

  const { t } = useTranslation();
  const isRaspberry = vendor === 'RaspberryPi';

  const onSelect = useCallback((firmwareVersion: string) => setSelectedVersion(firmwareVersion), []);

  const { data: firmwaresResponse, loading: firmwaresLoading } = useGetDeviceFirmwaresQuery();
  const firmwares = useMemo(
    () => firmwaresResponse?.getDeviceFirmwares?.filter(version => version !== currentVersion) ?? [],
    [currentVersion, firmwaresResponse?.getDeviceFirmwares],
  );
  const isLoading = useMemo(() => deviceUpdating || firmwaresLoading, [firmwaresLoading, deviceUpdating]);
  const latestVersion = useMemo(() => firmwares[0], [firmwares]);

  const handleCancelClick = useCallback(() => typeof onClose === 'function' && onClose(), [onClose]);
  const handleRebootClick = useCallback(() => {
    if (typeof onFirmwareSelect === 'function') {
      onFirmwareSelect(selectedVersion);
    }
  }, [onFirmwareSelect, selectedVersion]);

  const isVersionSelected = useMemo(() => Boolean(selectedVersion) || !isRaspberry, [selectedVersion, isRaspberry]);
  const isSelectedVersionOld = useMemo(() => {
    if (
      typeof selectedVersion === 'string' &&
      typeof currentVersion === 'string' &&
      semver.valid(selectedVersion) &&
      semver.valid(currentVersion)
    ) {
      return semver.gt(currentVersion, selectedVersion);
    }

    return false;
  }, [currentVersion, selectedVersion]);

  const raspberryText = t(
    'To upgrade a firmware version of the device select one from the list and click "Reboot" button. Latest version is ',
  );
  const otherText = t('To upgrade a firmware version of the device click "Reboot" button. Latest version is ');

  // TODO: Add translation keys!!!
  return (
    <Container>
      <p>
        {isRaspberry ? raspberryText : otherText}
        <strong>{latestVersion}</strong>
      </p>
      <WarningIcon color={colorTheme.warning} />
      <em>{t('Be carefull, device will be immediately rebooted!')}</em>
      <br />
      <InfoIcon color={colorTheme.info} />
      <em>{t('Current version of the device is ')}</em>
      {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
      <strong> {currentVersion}</strong>

      {isRaspberry && (
        <FormItem
          label={t('LATEST VERSIONS')}
          name="latestVersions"
          rules={[{ required: true, message: t('Please fill this field ') }]}>
          <Select disabled={isLoading} loading={isLoading} onChange={onSelect} placeholder={t('Select a version')}>
            {firmwares.map(firmwareVersion => (
              <Option key={firmwareVersion} value={firmwareVersion}>
                {firmwareVersion}
              </Option>
            ))}
          </Select>
        </FormItem>
      )}

      {isSelectedVersionOld && (
        <>
          <WarningIcon color={colorTheme.warning} />
          <em>{t('Be careful, you selected an old version!')}</em>
        </>
      )}
      <FooterModal>
        <Button type="default" htmlType="button" onClick={handleCancelClick}>
          {t('Cancel')}
        </Button>
        <Button
          type="primary"
          htmlType="button"
          loading={isLoading}
          onClick={handleRebootClick}
          disabled={!isVersionSelected}>
          {t('Reboot')}
        </Button>
      </FooterModal>
    </Container>
  );
};

export default memo(FirmwareVersionModal) as typeof FirmwareVersionModal;
