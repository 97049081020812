import React, { useCallback, memo, useMemo, useContext, useState, ComponentType, ReactNode } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import logger from 'src/helpers/logger';
import time from 'src/helpers/time';
import last from 'lodash/last';
import {
  RiDeleteBinLine,
  RiFilmLine,
  RiImageLine,
  RiLayoutMasonryLine,
  RiPlayList2Line,
  RiZoomInLine,
} from 'react-icons/ri';
import { CLOUDINARY_CLOUDNAME } from 'src/constants';
import { pascalCase, stopEventPropagation } from 'src/functions';
// GraphQL
import {
  useMutation,
  updateAfterMutation,
  useApolloError,
  getListKeyFromDataType,
  ApolloError,
} from '@fjedi/graphql-react-components';
import {
  MediaItem as MediaItemType,
  MediaItemType as MediaItemTypeType,
  Playlist as PlaylistType,
  Template as TemplateType,
  GetPlaylistsDocument,
  GetTemplatesDocument,
  GetMediaItemsDocument,
  UpdateMediaItemDocument,
  RemoveMediaItemDocument,
  UpdatePlaylistDocument,
  RemovePlaylistDocument,
  UpdateTemplateDocument,
  RemoveTemplateDocument,
  MediaFolderType,
} from 'src/graphql/generated';
import getPlaylistsQuery from 'src/graphql/queries/get-playlists.graphql';
import getMediaItemsQuery from 'src/graphql/queries/get-media-items.graphql';
import getTemplatesQuery from 'src/graphql/queries/get-templates.graphql';
import updateMediaItemMutation from 'src/graphql/mutations/update-media-item.graphql';
import updateTemplateMutation from 'src/graphql/mutations/update-template.graphql';
import updatePlaylistMutation from 'src/graphql/mutations/update-playlist.graphql';
import removeMediaItemMutation from 'src/graphql/mutations/remove-media-item.graphql';
import removePlaylistMutation from 'src/graphql/mutations/remove-playlist.graphql';
import removeTemplateMutation from 'src/graphql/mutations/remove-template.graphql';
import Tooltip from 'src/components/ui-kit/tooltip';
import { GhostEditableTitle } from 'src/components/ui-kit/typography';
import {
  // Image as RemoteImage,
  Video as RemoteVideo,
  // Transformation as MediaTransformation,
  getRemoteMediaURL,
} from 'src/components/ui-kit/remote-media';
import {
  MediaPlayerContext,
  MediaPlayerContextProps,
  PlayerData,
} from 'src/components/routes/private/media-library/media-player';
import type { MediaFolderItemProfile } from 'src/components/ui-kit/media-item/media-item.d';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';
import { DesktopIcon as DesktopIconStyle, CopyIcon } from 'src/components/ui-kit/icons';
import videoIcon from '../aside/tabs/video-icon.png';
import BrokenFileIcon from './broken-file.svg';
import Popconfirm from '../popconfirm';
import { Card, Body, Text, Button, Image } from './index';
import Spinner from '../spinner';
import MediaItemUsageContext from './context';
import Stub from './stub';
import emptyTemplate from './empty-template.png';
import emptyPlaylist from './empty-playlist.png';

const Title = styled(GhostEditableTitle)<{ showCopy: boolean }>`
  flex-grow: 1;

  &.ant-typography.ant-typography {
    color: #ffffff;
    font-weight: 600;
    line-height: 1.2;
    width: 100%;

    & > span.ghost-editable-title-text {
      flex-basis: 90%;
      margin-right: auto;
    }

    & > div.ant-typography-edit.ant-typography-edit {
      flex-basis: 10%;
      color: transparent;
      font-size: 1.125rem;

      &:focus,
      &:hover {
        color: #fff;
      }
    }
  }
`;

export const TypeContainer = styled.div`
  background-color: #ffffff;
  border: 1px solid hsl(0deg 0% 0% / 20%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2.5rem;
  color: #000000;
  font-size: 2rem;

  svg {
    color: #6473c4;
  }

  &.empty {
    .ant-image {
      top: -0.875rem;
    }
  }
`;

export const ThumbnailCard = styled(Card)<{ showFooter: boolean; isSelected: boolean; bgColor?: string }>`
  border-radius: 0.625rem;
  background-color: ${({ bgColor }) => bgColor || '#000'};

  ${TypeContainer}, .ant-image {
    border-radius: ${({ showFooter }) => (showFooter ? '0.625rem' : 0)};
    overflow: hidden;

    .ant-image-mask {
      position: absolute;
      background: rgba(0, 0, 0, 0.8);
      left: initial;
      bottom: initial;
      top: 0.5rem;
      right: 0.5rem;
      padding: 0 0.5rem;
      border-radius: 0.35rem;
      line-height: 1.5;
      opacity: 1;
    }
  }

  &,
  * {
    outline: none;
    &:not(input) {
      user-select: none;
    }
  }

  & > ${Body} {
    min-height: 2.5rem;
  }

  &:hover > ${Body} {
    .thumbnail-actions,
    .thumbnail-text {
      .ant-typography > .ant-typography-edit,
      .ant-btn {
        color: rgba(255, 255, 255, 0.4);

        &:hover {
          color: #fff;
        }
      }
    }
  }
`;
const ImageContainer = styled(Image)``;
const ImagePreviewMask = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  padding: 0.15rem;
  color: rgba(255, 255, 255, 0.8);
`;
const DesktopIcon = styled(DesktopIconStyle)`
  color: rgba(255, 255, 255, 0.8);
  height: 1em;
  width: 1em;
  font-size: 1rem;
`;

const PreviewIcon = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  left: initial;
  bottom: initial;
  top: 0.5rem;
  right: 0.5rem;
  border-radius: 0.35rem;
  opacity: 1;
  font-size: 13px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.375rem;
  width: 2.375rem;

  ${DesktopIcon} {
    color: rgba(255, 255, 255, 0.8);
  }

  svg {
    pointer-events: none;
  }
`;

const VideoRatio = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Actions = styled.div.attrs({
  className: 'thumbnail-actions',
})`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
`;

/* const RemoveIcon = styled(Icon)`
  margin-left: 0.25rem;
  margin-bottom: 0.25rem;
  cursor: pointer;
`; */

// const PlayButton = styled.div`
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   //
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   &:hover {
//     background-color: rgba(255, 255, 255, 0.1);
//     cursor: pointer;
//   }
// `;

// const PlayIcon = styled.img`
//   width: 40px;
//   height: 40px;
// `;

const ScreenRatioBadge = styled.div`
  position: absolute;
  background: #fff;
  color: #444;
  border: 1px solid #444;
  bottom: initial;
  top: 0.5rem;
  left: 0.5rem;
  border-radius: 0.65rem;
  opacity: 1;
  font-size: 12px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.05rem 0.5rem;
`;

export function isImage(type: MediaItemType['type']) {
  return ['IMAGE', 'LOGO', 'ICON'].includes(type);
}

export type MediaType = MediaItemTypeType.Image | MediaItemTypeType.Video;

export type MediaFolderTypeList =
  | MediaFolderType.Image
  | MediaFolderType.Video
  | MediaFolderType.Playlist
  | MediaFolderType.Template;

export const ICONS: Record<MediaFolderTypeList, ReactNode> = {
  IMAGE: <RiImageLine />,
  VIDEO: <RiFilmLine />,
  TEMPLATE: <RiLayoutMasonryLine />,
  PLAYLIST: <RiPlayList2Line />,
};

export const MediaIcon = styled.div`
  vertical-align: middle;
  margin-right: 0.4rem;

  > svg {
    display: inline-block;
    vertical-align: middle;
    height: 1.2rem;
    width: 1.2rem;
  }
`;

export type MediaThumbnailProps = {
  data: MediaFolderItemProfile;
  actions?: { key: string; component: ComponentType }[];
  onClick?: any;
  onRemove?: any;
  onDragStart?: any;
  onDragEnd?: any;
  isSelected?: boolean;
  isEditable?: boolean;
  isRemovable?: boolean;
  showPreview?: boolean;
  showCopy?: boolean;
  onCopy?(_success?: boolean): void;
  showControls?: boolean;
  loading?: boolean;
  showFooter?: boolean;
  stub?: boolean;
  width?: number | string;
  objectFit?: 'cover' | 'contain';
  className?: string;
  showMediaTypeIcon?: boolean;
};

//
export const MediaThumbnail: React.FC<MediaThumbnailProps> = props => {
  const { t } = useTranslation();
  const onError = useApolloError();
  //
  const {
    actions,
    data,
    loading = false,
    isSelected = true,
    onDragStart,
    onDragEnd,
    onClick,
    width = 240,
    onRemove,
    isRemovable = true,
    isEditable = true,
    showPreview = false,
    showCopy = false,
    onCopy,
    showControls = true,
    stub = false,
    showFooter = true,
    showMediaTypeIcon = false,
    objectFit = 'cover',
    className,
  } = props;
  const { __typename: typeName, id, title, projectId, createdAt } = data;
  const fileId = 'fileId' in data ? data.fileId : null;
  const type = ('type' in data ? data.type : null) as MediaItemType['type'];
  const url = 'url' in data ? data.url : null;
  const { screenRatio } = data as TemplateType;
  const isNewItem = useMemo(() => time().subtract(1, 'hour').isBefore(createdAt), [createdAt]);
  const showRemoveButton = useMemo(() => isRemovable && (!stub || !isNewItem), [stub, isNewItem, isRemovable]);

  const mediaType = useMemo<MediaFolderTypeList>(() => {
    if (isImage(type)) {
      return MediaFolderType.Image;
    }
    return (type || (typeName as string)?.toUpperCase?.()) as unknown as MediaFolderTypeList;
  }, [type, typeName]);
  //
  const { setIsUsageErrorVisible, setMediaUsages } = useContext(MediaItemUsageContext);
  const showUsageErrorModal = useCallback(
    (e: ApolloError) => {
      setIsUsageErrorVisible(true);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setMediaUsages(e.graphQLErrors[0].data.exception.data.usedPlaces);
    },
    [setIsUsageErrorVisible, setMediaUsages],
  );

  const queries: {
    getList: typeof GetPlaylistsDocument | typeof GetMediaItemsDocument | typeof GetTemplatesDocument;
    update: typeof UpdatePlaylistDocument | typeof UpdateTemplateDocument | typeof UpdateMediaItemDocument;
    remove: typeof RemovePlaylistDocument | typeof RemoveTemplateDocument | typeof RemoveMediaItemDocument;
  } = useMemo(() => {
    switch (typeName) {
      case 'Playlist':
        return {
          getList: getPlaylistsQuery,
          update: updatePlaylistMutation,
          remove: removePlaylistMutation,
        };
      case 'MediaItem':
        return {
          getList: getMediaItemsQuery,
          update: updateMediaItemMutation,
          remove: removeMediaItemMutation,
        };
      case 'Template':
        return {
          getList: getTemplatesQuery,
          update: updateTemplateMutation,
          remove: removeTemplateMutation,
        };
      default:
        throw new Error('Invalid type received inside MediaItemThumbnail component');
    }
  }, [typeName]);
  //
  const [update] = useMutation(queries.update, {
    onError,
  });
  const onChange = useCallback(
    (value: string) => {
      if (!value?.trim?.()) {
        return;
      }
      update({
        variables: {
          id,
          input: {
            title: value.trim(),
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          [`update${typeName}`]: {
            id,
            __typename: typeName,
            title: value.trim(),
          },
        },
      }).catch(logger);
    },
    [id, typeName, update],
  );

  const [remove, { loading: removing }] = useMutation(queries.remove, {
    update: updateAfterMutation(
      typeName as string,
      getListKeyFromDataType(typeName as string, { withGetPrefix: true }),
    ),
    onError: showUsageErrorModal,
    variables: { id },
    onCompleted() {
      logger(`${typeName} has been successfully removed`);
    },
  });

  const mediaPlayer: Required<MediaPlayerContextProps> = useContext(
    MediaPlayerContext,
  ) as Required<MediaPlayerContextProps>;

  const showPlaylist = useCallback(
    () => mediaPlayer.play({ id, typeName, screenRatio } as PlayerData),
    [id, mediaPlayer, screenRatio, typeName],
  );

  const showTemplate = useCallback(
    () => mediaPlayer.play({ id, typeName, screenRatio } as PlayerData),
    [id, mediaPlayer, screenRatio, typeName],
  );

  const [previewVisible, setPreviewVisible] = useState(false);
  const openPreview = useCallback(() => setPreviewVisible(true), []);
  const closePreview = useCallback((value: boolean, prevValue: boolean) => {
    if (prevValue) setPreviewVisible(value);
  }, []);

  const MediaComponent = useMemo(() => {
    let urlFilename;
    let remoteId: string[] | string;
    let remoteMediaURL: string;
    let remoteMediaThumbnailURL: string;
    let imagePlaceholder;

    switch (mediaType) {
      case 'VIDEO': {
        if (stub) {
          return <Stub />;
        }
        if ((url as string).includes('fjedi/video/upload')) {
          return (
            <VideoRatio>
              <RemoteVideo
                draggable={typeof onDragStart === 'function'}
                controls={showControls}
                style={{ width }}
                cloudName={CLOUDINARY_CLOUDNAME}
                publicId={getRemoteMediaURL(`${projectId}/${fileId}`, {
                  version: (data as MediaItemType).props?.version,
                  force_version: false,
                  resource_type: 'video',
                })}
                poster={getRemoteMediaURL(`${projectId}/${fileId}`, {
                  version: (data as MediaItemType).props?.version,
                  force_version: false,
                  resource_type: 'video',
                  format: 'jpg',
                })}
              />
            </VideoRatio>
          );
        }
        try {
          const { origin, pathname } = new URL(url as string);
          remoteMediaURL = `${origin}${pathname}`; // Omit any searchParams in media-file's url
          remoteMediaThumbnailURL = (data as MediaItemType)?.props?.thumbnailURL ?? remoteMediaURL;
        } catch (e) {
          console.error('Failed to get url for media-item', data, e);
          remoteMediaURL = BrokenFileIcon;
          remoteMediaThumbnailURL = BrokenFileIcon;
        }
        return (
          <VideoRatio>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              controls={showControls}
              poster={remoteMediaThumbnailURL}
              style={{ width }}
              draggable={typeof onDragStart === 'function'}>
              <source src={remoteMediaURL} type={(data as MediaItemType)?.props?.mimetype} />
            </video>
          </VideoRatio>
        );
      }
      case 'PLAYLIST': {
        const { items } = data as PlaylistType;
        return (
          <TypeContainer className="empty">
            {showPreview && items?.length && (
              <PreviewIcon onClick={showPlaylist}>
                <DesktopIcon />
              </PreviewIcon>
            )}
            <ImageContainer style={{ objectFit }} preview={false} src={emptyPlaylist} />
          </TypeContainer>
        );
      }
      case 'TEMPLATE': {
        const { preview: templatePreview } = data as TemplateType;
        if (templatePreview) {
          remoteId = templatePreview.path.split('/').slice(-2);
          const renderRemoteMedia = getRemoteMediaURL(remoteId.join('/'), {
            width,
            crop: 'thumb',
          });

          return (
            <>
              {screenRatio && (
                <ScreenRatioBadge>
                  <span>{screenRatio as string}</span>
                </ScreenRatioBadge>
              )}

              {showPreview && (
                <PreviewIcon onClick={showTemplate}>
                  <DesktopIcon />
                </PreviewIcon>
              )}

              <ImageContainer style={{ objectFit }} preview={false} src={renderRemoteMedia} />
            </>
          );
        }

        return (
          <TypeContainer className="empty">
            {screenRatio && (
              <ScreenRatioBadge>
                <span>{screenRatio as string}</span>
              </ScreenRatioBadge>
            )}
            <ImageContainer style={{ objectFit }} preview={false} src={emptyTemplate} />
          </TypeContainer>
        );
      }
      default: {
        urlFilename = last((url as string).split('/'));

        if ((url as string).includes('fjedi/image/upload')) {
          remoteId = `${projectId}/${urlFilename}`;
          remoteMediaURL = type === 'ICON' ? (url as string) : getRemoteMediaURL(remoteId);
          remoteMediaThumbnailURL =
            type === 'ICON'
              ? (url as string)
              : getRemoteMediaURL(remoteId, {
                  width,
                  crop: 'thumb',
                });
        } else {
          try {
            const { origin, pathname } = new URL(url as string);
            remoteMediaURL = `${origin}${pathname}`; // Omit any searchParams in media-file's url
            remoteMediaThumbnailURL = (data as MediaItemType)?.props?.thumbnailURL ?? remoteMediaURL;
          } catch (e) {
            console.error('Failed to get url for media-item', data, e);
            remoteMediaURL = BrokenFileIcon;
            remoteMediaThumbnailURL = BrokenFileIcon;
          }
        }

        const preview =
          showPreview && type !== 'ICON'
            ? {
                src: remoteMediaURL,
                maskClassName: 'custom-ant-image-preview-mask',
                mask: (
                  <ImagePreviewMask onClick={openPreview}>
                    <RiZoomInLine />
                  </ImagePreviewMask>
                ),
                visible: previewVisible,
              }
            : false;

        imagePlaceholder = isImage(type as MediaItemType['type']) ? (
          <ImageContainer src={remoteMediaThumbnailURL as string} preview={false} />
        ) : null;

        return stub ? (
          <Stub />
        ) : (
          <ImageContainer
            style={{ objectFit }}
            src={isImage(type) ? remoteMediaThumbnailURL : videoIcon}
            preview={preview}
            placeholder={imagePlaceholder}
            onPreviewClose={closePreview}
          />
        );
      }
    }
  }, [
    type,
    stub,
    onDragStart,
    showControls,
    width,
    projectId,
    fileId,
    data,
    showPreview,
    showPlaylist,
    objectFit,
    screenRatio,
    showTemplate,
    url,
    openPreview,
    previewVisible,
    closePreview,
    mediaType,
  ]);

  const executeParentOnCopy = useCallback(
    (success?: boolean) => {
      if (typeof onCopy === 'function') {
        onCopy(success);
      }
    },
    [onCopy],
  );

  const handleCopy = useCallback(() => {
    try {
      navigator?.clipboard?.writeText(JSON.stringify(data)).then(
        () => {
          executeParentOnCopy(true);
        },
        err => {
          executeParentOnCopy(false);
          logger(err as Error);
        },
      );
    } catch (err) {
      logger(err as Error);
      executeParentOnCopy(false);
    }
  }, [data, executeParentOnCopy]);
  //
  return (
    <Spinner spinning={loading || removing} wrapperClassName={className}>
      <ThumbnailCard
        style={{ cursor: stub ? 'progress' : 'pointer' }}
        showFooter={showFooter}
        isSelected={isSelected}
        bgColor={type === MediaItemTypeType.Logo ? 'rgb(189, 189, 189)' : '#000'}
        onClick={onClick}
        onDragStart={!stub && typeof onDragStart === 'function' ? onDragStart(data) : undefined}
        onDragEnd={!stub && typeof onDragEnd === 'function' ? onDragEnd(data) : undefined}>
        {MediaComponent}

        {Array.isArray(actions) && actions.length > 0 && (
          <Actions>
            {actions.map(action => {
              const { component: Component } = action as { key: string; component: ComponentType };
              return <Component key={action.key} />;
            })}
          </Actions>
        )}

        {showFooter && (
          <Body>
            <Text>
              {showMediaTypeIcon && (
                <Tooltip title={t(pascalCase(mediaType))}>
                  <MediaIcon>{ICONS[mediaType]}</MediaIcon>
                </Tooltip>
              )}

              {typeof title === 'string' && (
                <Title
                  level={5}
                  value={title as string}
                  onChange={onChange}
                  isEditable={isEditable}
                  autoSize={{ maxRows: 2, minRows: 1 }}
                  showCopy={showCopy}
                />
              )}
            </Text>
            <Actions>
              {showCopy && !stub && (
                <CopyToClipboard text={JSON.stringify(data)} onCopy={handleCopy}>
                  <Tooltip title={t('Copy')}>
                    <Button icon={<CopyIcon size="1.25rem" />} />
                  </Tooltip>
                </CopyToClipboard>
              )}
              {showRemoveButton && (
                <Popconfirm
                  placement="topRight"
                  title={`${t('Remove')}?`}
                  onClick={stopEventPropagation}
                  onCancel={stopEventPropagation}
                  onConfirm={onRemove || remove}
                  okText={t('Yes')}
                  cancelText={t('No')}>
                  <Tooltip title={t('Remove')} placement="bottom">
                    <Button>
                      <RiDeleteBinLine />
                    </Button>
                  </Tooltip>
                </Popconfirm>
              )}
            </Actions>
          </Body>
        )}
      </ThumbnailCard>
    </Spinner>
  );
};

MediaThumbnail.displayName = 'MediaThumbnail';

export default memo(MediaThumbnail) as typeof MediaThumbnail;
